@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-violet-100: #f0e9e1;
  --color-violet-50: #f2f0ec;
  --color-green-400: #1c8026;
  --color-dark-800: #333;
  --color-dark-900: #2e2e2e;
  --color-grey-10: #eceef2;
  --radius-main: 8px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-dark-800);
  background-color: var(--color-violet-100);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section {
  padding: 7rem 0;
}

@media only screen and (max-width: 1180px) {
  .section {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 30px 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.logo {
  width: 100%;
  max-width: 210px;
  margin-right: 16px;
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-500);
  margin-bottom: 16px;
  font-size: clamp(1.875rem, 1.3333rem + 2.4074vi, 3.5rem);
  line-height: 1.21;
  overflow: hidden;
}

h2, h3 {
  font-weight: var(--fw-600);
  margin-bottom: 16px;
  font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
  line-height: 1.2;
}

h3 {
  margin-bottom: 9px;
  font-size: 24px;
}

p {
  opacity: .6;
  font-size: 16px;
}

.subtext {
  opacity: 1;
  font-size: clamp(1rem, .9167rem + .3704vi, 1.25rem);
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-violet-100);
  padding: 23px 0 31px;
  transition-property: transform;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header.scroll {
  box-shadow: 0 0 5px #f3ecf0;
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 15px 0;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: 36px;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 32px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 15px;
  }
}

.nav__link {
  white-space: nowrap;
  transition: color .3s linear text-shadow .3s linear;
  font-size: 16px;
  line-height: 1.18;
}

.nav__link:hover {
  text-shadow: .4px .4px;
  color: var(--color-dark-900);
}

.nav__link.true {
  font-weight: var(--fw-800);
  color: var(--color-dark-900);
}

.nav__btns {
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.3);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-violet-100);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .nav .nav__item {
    margin-bottom: 30px;
  }

  .nav .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav .nav__link {
    font-size: 16px;
  }
}

[class].btn {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  height: max-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: var(--fw-600);
  white-space: nowrap;
  color: var(--color-white);
  background-color: var(--color-green-400);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  padding: 15px 45px;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-green-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
}

[class].btn.formsapp-button {
  margin: 0;
  font-family: var(--font-family-primary) !important;
}

[class].btn--reverse {
  border-color: var(--color-green-400);
  font-weight: var(--fw-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
  padding: 13px 32px;
  line-height: 1.11;
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

[class].btn--article {
  border-color: var(--color-dark-800);
  max-width: 100%;
  color: var(--color-dark-800);
  background-color: #0000;
  padding: 15px 24px;
}

[class].btn--request {
  padding: 8px 24px;
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;
}

[class].btn--request:hover, [class].btn--request:focus {
  border-color: var(--color-green-400);
  color: var(--color-green-400) !important;
  background-color: var(--color-white) !important;
}

[class].btn--sign {
  border-color: var(--color-dark-800);
  color: var(--color-dark-800);
  background-color: var(--color-white);
  padding: 8px 24px;
}

[class].btn--sign:hover, [class].btn--sign:focus {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

[class].btn--slider {
  z-index: 2;
  height: 32px;
  max-width: 32px;
  background-color: #0000;
  border-color: #0000;
  padding: 0;
  position: relative;
}

[class].btn--slider:before {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  background-color: var(--color-dark-800);
  transition: background-color .4s linear;
  position: absolute;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

[class].btn--slider:hover, [class].btn--slider:focus {
  background-color: #0000;
  border-color: #0000;
}

[class].btn--slider:hover:before, [class].btn--slider:focus:before {
  opacity: 1;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-dark-800);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 3rem;
}

.policy h1 {
  font-weight: var(--fw-600);
  margin-bottom: 3rem;
  font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
  line-height: 1.2;
}

.policy h2 {
  opacity: .6;
  margin-bottom: 9px;
  font-size: 20px;
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  letter-spacing: .02em;
  opacity: .6;
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  color: var(--color-green-400);
}

.policy p {
  font-size: 20px;
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-green-400);
  text-decoration: underline;
}

.footer {
  padding: 6.8125rem 0 22px;
}

.footer__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.footer__list {
  gap: 24px;
  display: flex;
}

.footer p {
  opacity: 1;
}

@media only screen and (max-width: 1180px) {
  .footer {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 992px) {
  .footer {
    padding-top: 30px;
  }

  .footer__list {
    gap: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding-top: 0;
  }

  .footer__inner {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contacts__wrapper {
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  gap: 24px;
  display: grid;
}

.contacts__content {
  width: 100%;
  max-width: 500px;
}

.contacts__form {
  align-items: center;
  gap: 24px;
  display: flex;
}

.contacts__form label {
  width: 100%;
}

.contacts__form input {
  border: 1px solid var(--color-dark-800);
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  max-width: 100%;
  font-weight: var(--fw-700);
  background-color: var(--color-white);
  padding: 14px;
  font-size: 16px;
  line-height: 1;
  position: relative;
}

.contacts h2 {
  margin-bottom: 16px;
  font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
}

.contacts .btn {
  max-width: 183px;
}

@media only screen and (max-width: 992px) {
  .contacts__wrapper {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .contacts__content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .contacts__form {
    flex-direction: column;
  }

  .contacts__form .btn {
    max-width: 100%;
  }
}

.hero {
  border-radius: calc(var(--radius-main) * 3);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.hero__inner {
  min-height: 652px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.hero__content {
  z-index: 1;
  width: 100%;
  max-width: 660px;
  padding-left: 16px;
  position: relative;
}

.hero--home {
  color: var(--color-white);
}

@media only screen and (max-width: 992px) {
  .hero {
    color: var(--color-dark-800);
  }

  .hero:before {
    content: "";
    z-index: 1;
    background: var(--color-white);
    opacity: .7;
    position: absolute;
    inset: 0;
  }

  .hero__inner {
    min-height: 350px;
  }

  .hero__content {
    max-width: 100%;
    padding: 16px;
  }
}

.article {
  border-radius: calc(var(--radius-main) * 4);
  height: 100%;
  background-color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
  overflow: hidden;
}

.article__content {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.article h3 {
  margin-bottom: 8px;
}

.article p {
  margin-bottom: 48px;
}

.article img {
  border-radius: calc(var(--radius-main) * 3);
  width: 100%;
  margin-bottom: 26px;
  overflow: hidden;
}

.article .btn {
  margin-top: auto;
}

@media only screen and (max-width: 992px) {
  .article {
    padding: 16px;
  }

  .article p {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .article img {
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

.product__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 7rem;
  display: grid;
}

.product__content {
  margin-bottom: 3rem;
}

.product__desc {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.product__desc p {
  margin-bottom: 32px;
}

.product__specification li {
  opacity: .6;
  padding-left: 24px;
  position: relative;
}

.product__specification li:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: var(--color-dark-800);
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 9px;
}

.product__benefits h2 {
  margin-bottom: 3rem;
}

.product__benefits ul {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.product__benefits li {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.product img {
  border-radius: calc(var(--radius-main) * 3);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.product__categories {
  color: var(--color-green-400);
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.5;
  display: block;
}

@media only screen and (max-width: 1180px) {
  .product__inner {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 992px) {
  .product__inner {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }

  .product__content {
    margin-bottom: 30px;
  }

  .product__desc {
    padding: 16px;
  }

  .product__benefits h2 {
    margin-bottom: 30px;
  }

  .product__benefits ul {
    grid-template-columns: repeat(2, 1fr);
  }

  .product__benefits li {
    padding: 16px;
  }

  .product__benefits li:last-child {
    grid-column: 2 span;
  }

  .product img {
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 576px) {
  .product__benefits ul {
    grid-template-columns: 1fr;
  }

  .product__benefits li:last-child {
    grid-column: auto;
  }
}

.advantages__content {
  margin-bottom: 3rem;
}

.advantages__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.advantages__item {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.advantages__item:before {
  content: "";
  width: 80px;
  height: 80px;
  background-image: url("../images/icons/locally.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-grey-10);
  border-radius: 50%;
  margin-bottom: 32px;
  display: block;
  position: relative;
}

.advantages__item--locally:before {
  background-image: url("../images/icons/locally.svg");
}

.advantages__item--organic:before {
  background-image: url("../images/icons/heart.svg");
}

.advantages__item--practices:before {
  background-image: url("../images/icons/practices.svg");
}

.advantages__item--mission:before {
  background-image: url("../images/icons/mission.svg");
}

.advantages__item--eco:before {
  background-image: url("../images/icons/like.svg");
}

.advantages__item--standarts:before {
  background-image: url("../images/icons/standarts.svg");
}

.advantages__item--recycling:before {
  background-image: url("../images/icons/recycling.svg");
}

.advantages__item--energy:before {
  background-image: url("../images/icons/energy.svg");
}

.advantages__item--certified:before {
  background-image: url("../images/icons/certified.svg");
}

.advantages h3 {
  margin-bottom: 9px;
}

.advantages--about {
  padding: 24px 0 7rem;
}

@media only screen and (max-width: 992px) {
  .advantages__content {
    margin-bottom: 30px;
  }

  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .advantages__item {
    padding: 16px;
  }

  .advantages__item:last-child {
    grid-column: 2 span;
  }

  .advantages--about {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .advantages__item:before {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .advantages__list {
    grid-template-columns: 1fr;
  }

  .advantages__item {
    padding: 16px;
  }

  .advantages__item:last-child {
    grid-column: auto;
  }
}

.products__content {
  margin-bottom: 3rem;
}

.products__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .products__content {
    margin-bottom: 30px;
  }

  .products__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .products__item:last-child {
    grid-column: 2 span;
  }

  .products__item:last-child h3 {
    text-align: center;
  }

  .products__item:last-child img {
    aspect-ratio: 18 / 9;
  }
}

@media only screen and (max-width: 576px) {
  .products__list {
    grid-template-columns: 1fr;
  }

  .products__item h3 {
    text-align: center;
  }

  .products__item .article__content {
    height: auto;
  }

  .products__item:last-child {
    grid-column: auto;
  }
}

.mission__inner {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 24px;
  display: grid;
}

.mission__image img {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  overflow: hidden;
}

.mission p {
  margin-bottom: 3rem;
  font-size: clamp(1rem, .9167rem + .3704vi, 1.25rem);
}

@media only screen and (max-width: 992px) {
  .mission__inner {
    grid-template-columns: 1fr;
  }

  .mission img {
    object-position: center;
    aspect-ratio: 2 / 1;
  }

  .mission p {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .mission .btn {
    max-width: 100%;
  }
}

.practices__content {
  margin-bottom: 48px;
}

.practices__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.practices__item {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.practices__item img {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  margin-bottom: 26px;
  overflow: hidden;
}

.practices__item h3 {
  margin-bottom: 8px;
}

.practices p {
  margin-bottom: 48px;
}

@media only screen and (max-width: 992px) {
  .practices__content {
    margin-bottom: 30px;
  }

  .practices__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .practices__item {
    padding: 16px;
  }

  .practices__item img {
    object-position: center;
    aspect-ratio: 18 / 9;
  }

  .practices__item:last-child {
    grid-column: 2 span;
  }

  .practices__item:last-child h3 {
    text-align: center;
  }

  .practices__item:last-child img {
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 576px) {
  .practices__list {
    grid-template-columns: 1fr;
  }

  .practices__item h3 {
    text-align: center;
  }

  .practices__item:last-child {
    grid-column: auto;
  }
}

.positive {
  padding-bottom: 7rem;
}

.positive__inner {
  position: relative;
}

.positive__content {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  max-width: 505px;
  background-color: var(--color-white);
  padding: 2rem;
  position: absolute;
  top: 20px;
  left: 32px;
  overflow: hidden;
}

.positive p:first-of-type {
  margin-bottom: 24px;
}

.positive span {
  font-weight: var(--fw-700);
}

.positive h2 {
  margin-bottom: 48px;
}

.positive img {
  border-radius: calc(var(--radius-main) * 3);
  overflow: hidden;
}

@media only screen and (max-width: 1180px) {
  .positive {
    padding-bottom: 60px;
  }

  .positive__content {
    padding: 16px;
    top: 0;
  }
}

@media only screen and (max-width: 992px) {
  .positive {
    padding-bottom: 30px;
  }

  .positive__content {
    max-width: 100%;
    position: relative;
    inset: 0;
  }

  .positive img {
    margin-bottom: 24px;
  }

  .positive h2 {
    margin-bottom: 30px;
  }
}

.conservation__content {
  width: 100%;
  max-width: 580px;
  margin-bottom: 3rem;
}

.conservation__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  display: grid;
}

.conservation__desc {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.conservation img {
  border-radius: calc(var(--radius-main) * 4);
  overflow: hidden;
}

.conservation h3 {
  margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
  .conservation__content {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .conservation__list {
    grid-template-columns: 1fr;
  }

  .conservation__list img {
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

.eco {
  padding-top: 7rem;
}

.eco__content {
  margin-bottom: 3rem;
}

.eco__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.eco__images img {
  border-radius: calc(var(--radius-main) * 3);
  width: 100%;
  overflow: hidden;
}

.eco__desc {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  overflow: hidden;
}

.eco h3 {
  margin-bottom: 8px;
}

.eco p {
  margin-bottom: 48px;
}

@media only screen and (max-width: 1180px) {
  .eco {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 992px) {
  .eco {
    padding-top: 30px;
  }

  .eco__inner {
    grid-template-columns: 1fr;
  }

  .eco__content, .eco p {
    margin-bottom: 30px;
  }

  .eco img {
    aspect-ratio: 18 / 9;
  }
}

.story__content {
  margin-bottom: 3rem;
}

.story__list {
  grid-template-columns: repeat(1, 1fr);
  gap: 5.5rem;
  display: grid;
}

.story__item {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 24px;
  display: grid;
}

.story__desc h3 {
  margin-bottom: 16px;
  font-size: clamp(1.25rem, 1rem + 1.1111vi, 2rem);
}

.story img {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .story__content {
    margin-bottom: 30px;
  }

  .story__list {
    gap: 24px;
  }

  .story__item {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .story__list {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .story__item {
    grid-template-columns: repeat(1, 1fr);
  }

  .story__desc {
    grid-row: 1 / span 1;
  }

  .story img {
    object-position: center;
    aspect-ratio: 2 / 1;
  }
}

.slider {
  overflow: hidden;
}

.slider__item {
  position: relative;
}

.slider__desc {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  max-width: 505px;
  background-color: var(--color-white);
  padding: 32px;
  position: absolute;
  top: 32px;
  left: 32px;
  overflow: hidden;
}

.slider__nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider__nav .swiper-pagination-bullet {
  --swiper-pagination-bullet-horizontal-gap: 8px;
  background: var(--color-dark-800);
  opacity: .2;
  border-radius: 50%;
  margin: 0;
}

.slider__nav .swiper-pagination-bullet-active {
  opacity: 1;
}

.slider__nav .swiper-pagination {
  z-index: 1;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 2px !important;
}

.slider__nav .btn.swiper-button-disabled {
  opacity: .2;
  pointer-events: none;
}

.slider__nav .btn--prev {
  scale: -1 1;
}

.slider img {
  border-radius: calc(var(--radius-main) * 3);
  overflow: hidden;
}

.slider__content, .slider__list {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 992px) {
  .slider__desc {
    padding: 16px;
  }

  .slider__content, .slider__list {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .slider__list {
    margin-bottom: 15px;
  }

  .slider__desc {
    max-width: 100%;
    padding-top: 24px;
    position: relative;
    inset: 0;
  }

  .slider img {
    margin-bottom: 24px;
  }
}

.catalog__swiper {
  overflow: hidden;
}

.catalog__swiper .swiper-slide {
  height: auto;
}

.catalog__list, .catalog h2 {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 992px) {
  .catalog h2 {
    margin-bottom: 30px;
  }
}

.categories__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.categories__item {
  border-radius: calc(var(--radius-main) * 4);
  background-color: var(--color-white);
  padding: 24px;
  position: relative;
  overflow: hidden;
}

.categories__item:hover h3 {
  color: var(--color-green-400);
}

.categories h2 {
  margin-bottom: 3rem;
}

.categories h3 {
  margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
  .categories__content {
    margin-bottom: 30px;
  }

  .categories__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .categories__item {
    padding: 16px;
  }

  .categories__item:last-child {
    grid-column: 2 span;
  }

  .categories h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .categories__list {
    grid-template-columns: 1fr;
  }

  .categories__item {
    padding: 16px;
  }

  .categories__item:last-child {
    grid-column: auto;
  }
}

/*# sourceMappingURL=main.css.map */
