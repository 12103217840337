@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.policy {
  &__content {
    &:not(:last-child) {
      margin-bottom: rem(48);
    }
  }

  h1 {
    margin-bottom: rem(48);
    font-weight: var(--fw-600);
    font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
    line-height: 1.2;
  }

  h2 {
    margin-bottom: 9px;
    font-size: 20px;
    opacity: 0.6;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  li {
    padding-left: 10px;
    letter-spacing: 0.02em;
    opacity: 0.6;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: "✓";
      color: var(--color-green-400);
    }
  }

  p {
    font-size: 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-green-400);
  }

  @include media(mobile-tablet) {
    &__content {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    h1 {
      margin-bottom: 30px;
    }
  }
}
