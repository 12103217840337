@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.products {
  &__content {
    margin-bottom: rem(48);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-child {
        grid-column: 2 span;

        h3 {
          text-align: center;
        }

        img {
          aspect-ratio: 18/9;
        }
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      h3 {
        text-align: center;
      }

      .article__content {
        height: auto;
      }

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
