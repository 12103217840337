@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.slider {
  overflow: hidden;

  &__item {
    position: relative;
  }

  &__desc {
    position: absolute;
    left: 32px;
    top: 32px;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: 32px;
    width: 100%;
    max-width: 505px;
    background-color: var(--color-white);
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper-pagination-bullet {
      --swiper-pagination-bullet-horizontal-gap: 8px;

      margin: 0;
      border-radius: 50%;
      background: var(--color-dark-800);
      opacity: 0.2;

      &-active {
        opacity: 1;
      }
    }

    .swiper-pagination {
      position: relative;
      bottom: 2px !important;
      z-index: 1;
      width: fit-content;
    }

    .btn {
      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &--prev {
        scale: -1 1;
      }
    }
  }

  img {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 3);
  }

  &__content,
  &__list {
    margin-bottom: rem(48);
  }

  @include media(tablet) {
    &__desc {
      padding: 16px;
    }

    &__content,
    &__list {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__list {
      margin-bottom: 15px;
    }

    &__desc {
      position: relative;
      padding-top: 24px;
      max-width: 100%;
      inset: 0;
    }

    img {
      margin-bottom: 24px;
    }
  }
}
