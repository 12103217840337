@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: calc(var(--radius-main) * 4);
  padding: 24px;
  height: 100%;
  background-color: var(--color-white);

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  h3 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 48px;
  }

  img {
    overflow: hidden;
    margin-bottom: 26px;
    border-radius: calc(var(--radius-main) * 3);
    width: 100%;
  }

  .btn {
    margin-top: auto;
  }

  @include media(tablet) {
    padding: 16px;

    p {
      margin-bottom: 16px;
    }
  }

  @include media(mobile-m) {
    img {
      object-position: center;
      aspect-ratio: 2/1;
    }
  }
}
