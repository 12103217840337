@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.catalog {
  &__swiper {
    overflow: hidden;

    .swiper-slide {
      height: auto;
    }
  }

  &__list {
    margin-bottom: rem(48);
  }

  h2 {
    margin-bottom: rem(48);
  }

  @include media(tablet) {
    h2 {
      margin-bottom: 30px;
    }
  }
}
