@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.mission {
  &__inner {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__image {
    img {
      overflow: hidden;
      border-radius: calc(var(--radius-main) * 4);
      width: 100%;
    }
  }

  p {
    margin-bottom: rem(48);
    font-size: clamp(1rem, 0.9167rem + 0.3704vi, 1.25rem);
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
    }

    img {
      object-position: center;
      aspect-ratio: 2/1;
    }

    p {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}
