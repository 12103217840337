@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.positive {
  padding-bottom: rem(112);

  &__inner {
    position: relative;
  }

  &__content {
    position: absolute;
    left: 32px;
    top: 20px;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: rem(32);
    width: 100%;
    max-width: 505px;
    background-color: var(--color-white);
  }

  p {
    &:first-of-type {
      margin-bottom: 24px;
    }
  }

  span {
    font-weight: var(--fw-700);
  }

  h2 {
    margin-bottom: 48px;
  }

  img {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 3);
  }

  @include media(tablet-wide) {
    padding-bottom: 60px;

    &__content {
      top: 0;
      padding: 16px;
    }
  }

  @include media(tablet) {
    padding-bottom: 30px;

    &__content {
      position: relative;
      max-width: 100%;
      inset: 0;
    }

    img {
      margin-bottom: 24px;
    }

    h2 {
      margin-bottom: 30px;
    }
  }
}
