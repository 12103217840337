@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.conservation {
  &__content {
    margin-bottom: rem(48);
    width: 100%;
    max-width: 580px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 24px;
    gap: 24px;
  }

  &__desc {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: 24px;
    background-color: var(--color-white);
  }

  img {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
  }

  h3 {
    margin-bottom: 8px;
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
      max-width: 100%;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;

      img {
        object-position: center;
        aspect-ratio: 2/1;
      }
    }
  }
}
