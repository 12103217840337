@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  margin-bottom: 16px;
  font-weight: var(--fw-500);
  font-size: clamp(1.875rem, 1.3333rem + 2.4074vi, 3.5rem);
  line-height: 1.21;
}

h2,
h3 {
  margin-bottom: 16px;
  font-weight: var(--fw-600);
  font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
  line-height: 1.2;
}

h3 {
  margin-bottom: 9px;
  font-size: 24px;
}

p {
  font-size: 16px;
  opacity: 0.6;
}

.subtext {
  font-size: clamp(1rem, 0.9167rem + 0.3704vi, 1.25rem);
  opacity: 1;
}
