@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.eco {
  padding-top: rem(112);

  &__content {
    margin-bottom: rem(48);
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__images {
    img {
      overflow: hidden;
      border-radius: calc(var(--radius-main) * 3);
      width: 100%;
    }
  }

  &__desc {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: 24px;
    background-color: var(--color-white);
  }

  h3 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 48px;
  }

  @include media(tablet-wide) {
    padding-top: 60px;
  }

  @include media(tablet) {
    padding-top: 30px;

    &__inner {
      grid-template-columns: 1fr;
    }

    &__content,
    p {
      margin-bottom: 30px;
    }

    img {
      aspect-ratio: 18/9;
    }
  }
}
