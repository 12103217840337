@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.footer {
  padding: rem(109) 0 22px;

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &__list {
    display: flex;
    gap: 24px;
  }

  p {
    opacity: 1;
  }

  @include media(tablet-wide) {
    padding-top: 60px;
  }

  @include media(tablet) {
    padding-top: 30px;

    &__list {
      gap: 16px;
    }
  }

  @include media(mobile-l) {
    padding-top: 0;

    &__inner {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
