@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contacts {
  &__wrapper {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__content {
    width: 100%;
    max-width: 500px;
  }

  &__form {
    display: flex;
    align-items: center;
    gap: 24px;

    label {
      width: 100%;
    }

    input {
      position: relative;
      border: 1px solid var(--color-dark-800);
      border-radius: calc(var(--radius-main) * 4);
      padding: 14px;
      width: 100%;
      max-width: 100%;
      font-weight: var(--fw-700);
      font-size: 16px;
      line-height: 1;
      background-color: var(--color-white);
    }
  }

  h2 {
    margin-bottom: 16px;
    font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
  }

  .btn {
    max-width: 183px;
  }

  @include media(tablet) {
    &__wrapper {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    &__content {
      max-width: 100%;
    }
  }

  @include media(mobile-s) {
    &__form {
      flex-direction: column;

      .btn {
        max-width: 100%;
      }
    }
  }
}
