@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.product {
  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: rem(112);
    gap: 24px;
  }

  &__content {
    margin-bottom: rem(48);
  }

  &__desc {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: 24px;
    background-color: var(--color-white);

    p {
      margin-bottom: 32px;
    }
  }

  &__specification {
    li {
      position: relative;
      padding-left: 24px;
      opacity: 0.6;

      &::before {
        content: "";
        position: absolute;
        left: 9px;
        top: 9px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: var(--color-dark-800);
      }
    }
  }

  &__benefits {
    h2 {
      margin-bottom: rem(48);
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }

    li {
      overflow: hidden;
      border-radius: calc(var(--radius-main) * 4);
      padding: 24px;
      background-color: var(--color-white);
    }
  }

  img {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 3);
    width: 100%;
    height: 100%;
  }

  &__categories {
    display: block;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 1.5;
    color: var(--color-green-400);
  }

  @include media(tablet-wide) {
    &__inner {
      margin-bottom: 60px;
    }
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }

    &__content {
      margin-bottom: 30px;
    }

    &__desc {
      padding: 16px;
    }

    &__benefits {
      h2 {
        margin-bottom: 30px;
      }

      ul {
        grid-template-columns: repeat(2, 1fr);
      }

      li {
        padding: 16px;

        &:last-child {
          grid-column: 2 span;
        }
      }
    }

    img {
      object-position: center;
      aspect-ratio: 2/1;
    }
  }

  @include media(mobile-m) {
    &__benefits {
      ul {
        grid-template-columns: 1fr;
      }

      li {
        &:last-child {
          grid-column: auto;
        }
      }
    }
  }
}
