@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.practices {
  &__content {
    margin-bottom: 48px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: 24px;
    background-color: var(--color-white);

    img {
      overflow: hidden;
      margin-bottom: 26px;
      border-radius: calc(var(--radius-main) * 4);
      width: 100%;
    }

    h3 {
      margin-bottom: 8px;
    }
  }

  p {
    margin-bottom: 48px;
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding: 16px;

      img {
        object-position: center;
        aspect-ratio: 18/9;
      }

      &:last-child {
        grid-column: 2 span;

        h3 {
          text-align: center;
        }

        img {
          aspect-ratio: 2/1;
        }
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      h3 {
        text-align: center;
      }

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
