@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.advantages {
  $root: &;

  &__content {
    margin-bottom: rem(48);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    padding: 24px;
    background-color: var(--color-white);

    &::before {
      content: "";
      position: relative;
      display: block;
      margin-bottom: 32px;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      background-image: url("../images/icons/locally.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: var(--color-grey-10);
    }

    &--locally {
      &::before {
        background-image: url("../images/icons/locally.svg");
      }
    }

    &--organic {
      &::before {
        background-image: url("../images/icons/heart.svg");
      }
    }

    &--practices {
      &::before {
        background-image: url("../images/icons/practices.svg");
      }
    }

    &--mission {
      &::before {
        background-image: url("../images/icons/mission.svg");
      }
    }

    &--eco {
      &::before {
        background-image: url("../images/icons/like.svg");
      }
    }

    &--standarts {
      &::before {
        background-image: url("../images/icons/standarts.svg");
      }
    }

    &--recycling {
      &::before {
        background-image: url("../images/icons/recycling.svg");
      }
    }

    &--energy {
      &::before {
        background-image: url("../images/icons/energy.svg");
      }
    }

    &--certified {
      &::before {
        background-image: url("../images/icons/certified.svg");
      }
    }
  }

  h3 {
    margin-bottom: 9px;
  }

  &--about {
    padding: 24px 0 rem(112);
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding: 16px;

      &:last-child {
        grid-column: 2 span;
      }
    }

    &--about {
      padding-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__item {
      &::before {
        margin-bottom: 16px;
        width: 50px;
        height: 50px;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      padding: 16px;

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
