@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 23px 0 31px;
  width: 100%;
  background-color: var(--color-violet-100);
  transition-property: transform;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-200%);
  }

  &.scroll {
    box-shadow: 0 0 5px 0 #f3ecf0;
  }

  @include media(tablet) {
    padding: 15px 0;
  }
}
