@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  position: relative;
  overflow: hidden;
  border-radius: calc(var(--radius-main) * 3);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 652px;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding-left: 16px;
    width: 100%;
    max-width: 660px;
  }

  &--home {
    color: var(--color-white);
  }

  @include media(tablet) {
    color: var(--color-dark-800);

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: var(--color-white);
      opacity: 0.7;
      inset: 0;
    }

    &__inner {
      min-height: 350px;
    }

    &__content {
      padding: 16px;
      max-width: 100%;
    }
  }
}
