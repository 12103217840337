@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: 36px;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 32px;

      @include media(tablet-wide) {
        margin-right: 15px;
      }
    }
  }

  &__link {
    font-size: 16px;
    line-height: 1.18;
    white-space: nowrap;
    transition: color 0.3s linear text-shadow 0.3s linear;

    &:hover {
      text-shadow: 0.4px 0.4px 0 currentcolor;
      color: var(--color-dark-900);
    }

    &.true {
      font-weight: var(--fw-800);
      color: var(--color-dark-900);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @include media(tablet-wide) {
    &__list {
      margin-right: 16px;
    }
  }

  @include media(tablet) {
    &__inner {
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.3);
      width: 100%;
      height: var(--vh);
      max-width: 350px;
      background-color: var(--color-violet-100);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }

    .nav__list {
      z-index: -1;
      flex-direction: column;
    }

    .nav__item {
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    .nav__link {
      font-size: 16px;
    }
  }
}
